* {
    margin: 0;
    padding: 0;
    font-family: 'Noto Sans KR', sans-serif;
}

/* 노션 관련 코드 */
.notion .notion-page-icon-wrapper {
    justify-content: start !important;
}

.notion > * {
    padding: 0 !important;
}

.notion .notion-asset-wrapper-image > div {
    height: unset !important;
} 

.notion .notion-asset-wrapper-image {
    flex-direction: row !important;
}

.notion .notion-frame .notion-page-no-cover {
    margin-top: 160px !important;
}

