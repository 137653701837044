:root {
    --teal0: #EAF9F8;
    --teal1: #BCECE8;
    --teal2: #94E9E3;
    --teal3: #55DBD0;
    --teal4: #4FCCC3;
    --teal5: #4CC2B9; /* Highlight */
    --teal6: #48ACA5;
    --teal7: #469B94;
    --teal8: #3D857F;
    --teal9: #2D6560; /* Brand */

    --grey0: #F8F9FA;
    --grey1: #F1F3F5; /* Background */
    --grey2: #E9ECEF;
    --grey3: #DEE2E6; /* Border */
    --grey4: #ced4da;
    --grey5: #ADB5BD; /* Placeholder */
    --grey6: #868E96;
    --grey7: #4A5157;
    --grey8: #353B41;
    --grey9: #1E2226;
    --greyPlaceholder: rgba(173, 181, 189, 0.3);

    --blue0: #EEF7FF;
    --blue1: #D9EEFF;
    --blue2: #B5DDFF;
    --blue3: #9ED2FF;
    --blue4: #88C7FC;
    --blue5: #78C1FF;
    --blue6: #5099F4;
    --blue7: #468FEA;
    --blue8: #2871CC;
    --blue9: #003F9A;

    --red0: #FFF5F5;
    --red1: #FFE3E3;
    --red2: #FFC9C9;
    --red3: #FFA8A8;
    --red4: #FF8787;
    --red5: #FF6B6B;
    --red6: #FA5252;
    --red7: #F03E3E;
    --red8: #E03131;
    --red9: #C92A2A;
}

:root {
    --box-shadow: 0px 4px 8px var(--grey1);
}

:root {
    --line-height-base: 1.6;
    --line-height-adjust: 1.3;
    --font-size-h1: 56px;
    --font-size-h2: 48px;
    --font-size-h3: 36px;
    --font-size-h4: 32px;
    --font-size-h5: 24px;
    --font-size-h6: 20px;
    --font-size-h7: 17px;
    --font-size-p: 15px;
    --font-size-sm: 13px;
    --font-weight-bold: bold;
    --font-weight-semibold: 600;
    --font-weight-medium: 500;
    --font-weight-regular: normal;
}